import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Shared/Navbar";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
// import Home from './Components/Home/Booking';
import HomePage from "./Pages/HomePage";
import Biletat from "./Pages/Biletat";
import Menaxhimi from "./Pages/Menaxhimi";
import LoginPage from "./Pages/LoginPage";
import RegisterPage from "./Pages/RegisterPage";
import Mainpage from "./Pages/Mainpage";

function App() {
    return (
        <div className="App">
            <Router>
                {/* <Navbar /> */}
                <Routes>
                    <Route exact path="/Home" element={<HomePage />} />
                    <Route exact path="/bileta" element={<Biletat />} />
                    <Route exact path="/menaxhimi" element={<Menaxhimi/>} />
                    <Route exact path="/adminpage" element={<LoginPage/>}/>
                    <Route exact path="/" element={<Mainpage/>}/>
                    {/* Uncomment if you decide to use RegisterPage */}
                    {/* <Route exact path="/register" element={<RegisterPage/>}/> */}
                </Routes>
                {/* <Footer /> */}
            </Router>
        </div>
    );
}

export default App;
