import destination1 from "./images/destination-1.jpg";
import destination2 from "./images/destination-2.jpg";
import destination3 from "./images/destination-3.avif";
import destination4 from "./images/destination-4.webp";
import destination5 from "./images/destination-5.avif";
import destination6 from "./images/destination-6.webp";
import destination7 from "./images/destination-7.avif";
// import destination8 from "./images/destination-8.jpg";
import destination9 from "./images/destination-9.jpg";
import destination10 from "./images/destination-10.jpeg";
import destination11 from "./images/destination-11.jpg";
import destination12 from "./images/destination-12.webp";
import profile from "./images/profile.avif";

// gallery
import gallery1 from "./images/corfu-hotel.jpg";
import gallery2 from "./images/bus-in-road.webp";
import gallery3 from "./images/berlin.webp";
import gallery4 from "./images/beach.jpg";
import gallery5 from "./images/milan.webp";
import gallery6 from "./images/ferry.webp";

export const studentsData = [
    {
        profilePic: profile,
        studentName: "Alisa Qatipi",
        comment: "Udhëtimi im ishte fantastik! Agjencia ofron shërbime të mrekullueshme dhe organizim të përkryer."
       
    },
    {
        profilePic: profile,
        studentName: "Idriz Gashi",
        comment: "Ishte hera e parë që udhëtoja me autobus, por nuk u zhgënjeva fare. Stafi ishte shumë i përgatitur dhe ja kalova shumë mirë."
      
    },
    {
        profilePic: profile,
        studentName: "Albert Cangu",
        comment: "Njisha"
        
    },
    {
        profilePic: profile,
        studentName: "Lirie Krasniqi",
        comment: "Agjencinë ma rekomanduan dhe më duhet ta pranoj se nuk u zhgënjeva."
       
    },
    {
        profilePic: profile,
        studentName: "Klaudio",
        comment: "Agjencia me e mire, korrekte dhe e gatshme per te na qendruar ne dispozicion derisa arriten ne destinacion."
    },
    {
        profilePic: profile,
        studentName: "Kristi",
        comment: "Very helpful staff. I needed a trip to Munchen and the bus was very comfortable."
    },
];


export const destinationsData = [
    {
        img: destination1,
        name: "Itali"
    },
    {
        img: destination2,
        name: "Spanjë"
    },
    {
        img: destination3,
        name: "Greqi"
    },
    {
        img: destination4,
        name: "Gjermani"
    },
    {
        img: destination9,
        name: "Francë"
    },
    {
        img: destination5,
        name: "Belgjikë"
    },

    
]


export const galleryImages = [
    {
        imageUrl: gallery1
    },
    {
        imageUrl: gallery2
    },
    {
        imageUrl: gallery3
    },
    {
        imageUrl: gallery4
    },
    {
        imageUrl: gallery5
    },
    {
        imageUrl: gallery6
    }
]

export const offerData = [
    {
        img: destination6,
        name: "Kroaci",
    },
    {
        img: destination7,
        name: "Mal i Zi",
    },
    {
        img: destination10,
        name: "Turqi",
    },
    {
        img: destination11,
        name: "Austri",
    },
    {
        img: destination12,
        name: "Zvicër",
    },
]