import React from "react";
import { FaFacebookF, FaGithub, FaGoogle, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import jwtDecode from "jwt-decode"; // Import the jwt-decode library

const Footer = () => {
    const token = localStorage.getItem("token");

    let decodedToken;
    try {
      decodedToken = jwtDecode(token);
      console.log("Decoded Token:", decodedToken);
    } catch (error) {
      console.error("Error decoding token:", error);
    }

    function logout() {
        localStorage.removeItem('token');
        window.location.href = "/";
      }
  return (
    <div className="footer">
      <footer class="bg-dark text-white">
        <div class=" p-3 d-flex justify-content-between align-items-center  " style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
          {token ? (
            <p>You are logged in as {decodedToken.username} with role {decodedToken.role}.</p>
          ) : (
            <p>You are not logged in.</p>
          )}
          <button className="btn btn-secondary" onClick={logout}>Log Out</button>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
