import React from 'react'
import SideTab from '../Components/Menaxhim/SideTab'
import Navbar from '../Shared/Navbar'
import Footer from '../Shared/Footer'
import './Styles/Menaxhimi.scss'

const Menaxhimi = () => {
  const token = localStorage.getItem("token");
  
  return (
    <div className='menaxhimi-page'>
      {/* {token.role === 'admin' ? (
        <>
        <Navbar/>
      <SideTab/>
      <Footer/>
        </>
      ) : null} */}
       <Navbar/>
      <SideTab/>
      <Footer/>
    </div>
  )
}

export default Menaxhimi