import React from 'react'
import Navbar from '../Shared/Navbar'
import LoginForm from '../Components/Authentication/LoginForm'

const LoginPage = () => {
  return (
    <div>
        {/* <Navbar/> */}
        <LoginForm/>
    </div>
  )
}

export default LoginPage