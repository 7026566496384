import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";

const AgjentetModal = ({ closeModal, data, updateTicket }) => {
  const token = localStorage.getItem("token");

  const [neto, setNeto] = useState(data.neto);
  const [likujduar, setLikujduar] = useState(data.likujduar);

  useEffect(() => {
    if (likujduar === "Po") {
      setNeto(0);
    } else {
      setNeto(data.neto);
    }
  }, [likujduar, data.neto]);

  const handleEditReservation = async (e) => {
    e.preventDefault();

    const formattedDateBooked = new Date(data.date_booked)
      .toISOString()
      .split("T")[0];
    const formattedDepartureDate = new Date(data.departure_date)
      .toISOString()
      .split("T")[0];
    const formattedArrivalDate = new Date(data.arrival_date)
      ? new Date(data.arrival_date).toISOString().split("T")[0]
      : null;

    const editReservationData = {
      date_booked: formattedDateBooked,
      series: e.target.series.value,
      departure_time: e.target.departure_time.value,
      agjenti: e.target.agjenti.value,
      phone_no: e.target.phone_no.value,
      client_name: e.target.client_name.value,
      client_surname: e.target.client_surname.value,
      departure_date: formattedDepartureDate,
      arrival_date: formattedArrivalDate,
      departure_location: e.target.departure_location.value,
      arrival_location: e.target.arrival_location.value,
      price: e.target.price.value,
      tsc: e.target.tsc.value,
      profit: e.target.profit.value,
      neto: neto,
      likujduar: likujduar,
      company_name: e.target.company_name.value,
      id: data.id,
    };

    updateTicket(editReservationData);

    try {
      const response = await axios.put(
        `https://amr-travel.al/php/entries.php?id=${data.id}`,
        editReservationData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response", response);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      contentLabel="Edit Ticket"
      ariaHideApp={false}
      className="shared-modal"
    >
      <div className="modali">
        <div className="modal-contenti ps-2 pe-2">
          <div className="mx-auto d-flex flex-row me-4 ms-3">
            <h2 className="mt-2 text-left mr-auto p-2 col-8 title">
              Update your reservation
            </h2>
            <h2 className="close mt-2 p-2 col-4 text-end" onClick={closeModal}>
              X
            </h2>
          </div>
          <form
            className="needs-validation mt-3 ps-4 pe-4"
            onSubmit={handleEditReservation}
          >
            <div className="row g-3">
              <div className="col-lg-3 col-md-12">
                <label htmlFor="dateBooked" className="form-label">
                  Data e prerjes
                </label>
                <DatePicker
                  className="form-control"
                  selected={new Date(data.date_booked)}
                  placeholderText="MM/DD/YYYY"
                  showIcon
                  selectsStart
                  startDate={new Date(data.date_booked)}
                  name="date_booked"
                />
              </div>
              <div className="col-lg-3 col-md-12">
                <label htmlFor="seria" className="form-label">
                  Seria
                </label>
                <input
                  defaultValue={data.series}
                  type="text"
                  className="form-control"
                  id="seria"
                  name="series"
                />
              </div>
              <div className="col-lg-3 col-md-12">
                <label htmlFor="time" className="form-label">
                  Ora Nisjes
                </label>
                <input
                  defaultValue={data.departure_time}
                  type="text"
                  className="form-control"
                  id="time"
                  name="departure_time"
                />
              </div>
              <div className="col-lg-3 col-md-12">
                <label htmlFor="agjenti" className="form-label">
                  Agjenti
                </label>
                <input
                  defaultValue={data.agjenti}
                  type="text"
                  className="form-control"
                  placeholder="agjenti"
                  id="agjenti"
                  name="agjenti"
                />
              </div>
              <div className="row mt-3">
                <div className="col-lg-3 col-md-12">
                  <label htmlFor="client_name" className="form-label">
                    Emri
                  </label>
                  <input
                    defaultValue={data.client_name}
                    type="text"
                    className="form-control"
                    placeholder="client_name"
                    id="client_name"
                    name="client_name"
                  />
                </div>
                <div className="col-lg-3 col-md-12">
                  <label htmlFor="client_surname" className="form-label">
                    Mbiemer
                  </label>
                  <input
                    defaultValue={data.client_surname}
                    type="text"
                    className="form-control"
                    placeholder="client_surname"
                    id="client_surname"
                    name="client_surname"
                  />
                </div>
                <div className="col-lg-3 col-md-12">
                  <label htmlFor="phone_no" className="form-label">
                    Nr Cel
                  </label>
                  <input
                    defaultValue={data.phone_no}
                    type="text"
                    className="form-control"
                    placeholder="phone_no"
                    id="phone_no"
                    name="phone_no"
                  />
                </div>
                <div className="col-lg-3 col-md-12">
                  <label htmlFor="company_name" className="form-label">
                    Kompania
                  </label>
                  <input
                    defaultValue={data.company_name}
                    type="text"
                    readOnly
                    className="form-control"
                    placeholder="company_name"
                    id="company_name"
                    name="company_name"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-3 col-md-12">
                  <label htmlFor="departure_location" className="form-label">
                    Nisja
                  </label>
                  <input
                    defaultValue={data.departure_location}
                    type="text"
                    readOnly
                    className="form-control"
                    placeholder="departure_location"
                    id="departure_location"
                    name="departure_location"
                  />
                </div>
                <div className="col-lg-3 col-md-12">
                  <label htmlFor="arrival_location" className="form-label">
                    Arrita
                  </label>
                  <input
                    defaultValue={data.arrival_location}
                    type="text"
                    readOnly
                    className="form-control"
                    placeholder="arrival_location"
                    id="arrival_location"
                    name="arrival_location"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-md-12">
                  <label htmlFor="nisja" className="form-label">
                    Data Nisjes
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={new Date(data.departure_date)}
                    placeholderText="MM/DD/YYYY"
                    showIcon
                    selectsStart
                    startDate={new Date(data.departure_date)}
                    name="departure_date"
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <label htmlFor="kthimi" className="form-label">
                    Data Kthimit
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={new Date(data.arrival_date)}
                    placeholderText="MM/DD/YYYY"
                    showIcon
                    selectsStart
                    startDate={new Date(data.departure_date)}
                    name="arrival_date"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-3 col-md-12">
                  <label htmlFor="price" className="form-label">
                    Cmimi
                  </label>
                  <input
                    defaultValue={data.price}
                    type="text"
                    className="form-control"
                    placeholder="price"
                    id="price"
                    name="price"
                  />
                </div>

                <div className="col-lg-3 col-md-12">
                  <label htmlFor="tsc" className="form-label">
                    Tsc
                  </label>
                  <input
                    defaultValue={data.tsc}
                    type="text"
                    className="form-control"
                    placeholder="tsc"
                    id="tsc"
                    name="tsc"
                  />
                </div>
                <div className="col-lg-3 col-md-12">
                  <label htmlFor="profit" className="form-label">
                    Profit
                  </label>
                  <input
                    defaultValue={data.profit}
                    type="text"
                    className="form-control"
                    placeholder="profit"
                    id="profit"
                    name="profit"
                  />
                </div>
                <div className="col-lg-3 col-md-12">
                  <label htmlFor="neto" className="form-label">
                    Neto
                  </label>
                  {likujduar === "Po" ? (
                    <input
                      defaultValue={neto}
                      type="text"
                      className="form-control"
                      placeholder="neto"
                      id="neto"
                      name="neto"
                      readOnly
                    />
                  ) : (
                    <input
                      defaultValue={neto}
                      type="text"
                      className="form-control"
                      placeholder="neto"
                      id="neto"
                      name="neto"
                      onChange={(e) => setNeto(e.target.value)}
                    />
                  )}
                </div>

                <div className="row mt-3">
                  <div className="col-lg-3 col-md-12">
                    <label htmlFor="likujdimi" className="form-label">
                      Likujduar
                    </label>
                    <select
                      className="form-select"
                      name="likujduar"
                      id="likujdimi"
                      value={likujduar}
                      onChange={(e) => {
                        setLikujduar(e.target.value);
                      }}
                    >
                      <option value="Po">Po</option>
                      <option value="Jo">Jo</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center">
                <button className="btn btn-lg btn-warning mt-2 mb-4" type="submit">
                  Update Reservation
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AgjentetModal;
