import React from 'react'
import SideTab from '../Components/Biletat/SideTab'
import Navbar from '../Shared/Navbar'
import Footer from '../Shared/Footer'
import './Styles/Biletat.scss'

const Biletat = () => {
  return (
    <>
    <div className="biletat-page">
      <Navbar/>
        <SideTab/>
        <Footer/>
    </div>
    </>
  )
}

export default Biletat