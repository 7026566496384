import React, { useState, useEffect } from "react";
import "../Style/Booking.scss";
import axios from "axios";
//toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import { FaCheck } from "react-icons/fa";
import jwt_decode from "jwt-decode";
import "react-datepicker/dist/react-datepicker.css";

const Booking = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwt_decode(token);
  const userId = decodedToken.user_id;

  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [departureLocation, setDepartureLocation] = useState("");
  const [arrivalLocation, setArrivalLocation] = useState("");
  const [departureDate, setDepartureDate] = useState(new Date());
  const [arrivalDate, setArrivalDate] = useState(null);
  const [price, setPrice] = useState("");
  const [tsc, setTsc] = useState(0);
  const [profit, setProfit] = useState(0);
  const [likujdimi, setLikujdimi] = useState(0);
  const [dateBooked, setDateBooked] = useState(new Date());
  const [seria, setSeria] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const [agjenti, setAgjenti] = useState("");
  const [likujduar, setLikujduar] = useState("Jo");

  const [user, setUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const [chosenTsc, setChosenTsc] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [companyCities, setCompanyCities] = useState([]);

  const showCompanies = async () => {
    try {
      const response = await axios.get(
        "https://amr-travel.al/php/company.php",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCompanyList(response.data);
      if (response.data.length > 0) {
        const firstCompany = response.data[0];
        setSelectedCompany(firstCompany.company_name);
        setCompanyCities(JSON.parse(firstCompany.cities));
        setTsc(firstCompany.tsc);
        setChosenTsc(firstCompany.chosenTsc);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const showUsers = async () => {
    try {
      const response = await axios.get("https://amr-travel.al/php/user.php", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(response.data);
      if (response.data.length > 0) {
        setSelectedUser(response.data[0].username);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    showCompanies();
    showUsers();
  }, []);

  useEffect(() => {
    if (companyList.length > 0) {
      const selectedCompanyData = companyList.find(
        (company) => company.company_name === selectedCompany
      );
      if (selectedCompanyData) {
        setCompanyCities(JSON.parse(selectedCompanyData.cities));
        setTsc(selectedCompanyData.tsc);
        setChosenTsc(selectedCompanyData.chosenTsc);
      }
    }
  }, [selectedCompany, companyList]);

  const handleLikujduar = () => {
    setLikujduar("Po");
    setLikujdimi(0)
  };

  const handleLikujdimi = () => {
    let likujdimi;
    if (chosenTsc === "fikse") {
      likujdimi = price - tsc;
    } else {
      likujdimi = price - (price * tsc) / 100;
    }
    setLikujdimi(likujdimi);
  };

  const handleProfit = () => {
    let fitimi;
    if (chosenTsc === "fikse") {
      fitimi = tsc;
    } else {
      fitimi = (price * tsc) / 100;
    }
    setProfit(fitimi);
  };

  useEffect(() => {
    handleLikujdimi();
    handleProfit();
  }, [tsc, price, chosenTsc]);

  const addBooking = async (e) => {
    e.preventDefault();

    const formattedDateBooked = dateBooked.toISOString().split("T")[0];
    const formattedDepartureDate = departureDate.toISOString().split("T")[0];
    const formattedArrivalDate = arrivalDate
      ? arrivalDate.toISOString().split("T")[0]
      : null;

    const bookingData = {
      clientName,
      clientSurname,
      phoneNo,
      companyName: selectedCompany,
      departureLocation,
      arrivalLocation,
      departureDate: formattedDepartureDate,
      arrivalDate: formattedArrivalDate,
      price,
      tsc,
      profit,
      likujdimi,
      dateBooked: formattedDateBooked,
      seria,
      departureTime,
      agjenti: selectedUser,
      likujduar,
    };

    try {
      await axios.post(
        "https://amr-travel.al/php/entries.php",
        JSON.stringify(bookingData),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Reset form fields upon successful submission
      setClientName("");
      setClientSurname("");
      setPhoneNo("");
      setSelectedCompany("");
      setDepartureLocation("");
      setArrivalLocation("");
      setDepartureDate(new Date());
      setArrivalDate(null);
      setPrice("");
      setTsc(0);
      setProfit(0);
      setLikujdimi(0);
      setSeria("");
      setDateBooked(new Date());
      setDepartureTime("");
      setAgjenti("");
      setLikujduar("Jo");

      toast.success("Bileta u regjistrua!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log("Booking error: ", error);
      if (error.response) {
        console.log("Response data: ", error.response.data);
        console.log("Response status: ", error.response.status);
        console.log("Response headers: ", error.response.headers);
      } else if (error.request) {
        console.log("Request data: ", error.request);
      } else {
        console.log("Error message: ", error.message);
      }

      toast.error(
        "There was an error processing your request. Please try again later.",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  return (
    <>
      <div id="booking" className="section booking">
        <div className="section-center pt-5 pb-4">
          <div className="container">
            <div className="row">
              <div className="booking-form">
                <div className="form-header">
                  <h1>Shto rezervimin e ri</h1>
                </div>
                <form style={{height: "60vh"}} onSubmit={addBooking}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <DatePicker
                          className="form-control w-100"
                          placeholderText="MM/DD/YYYY"
                          selected={dateBooked}
                          onChange={(date) => setDateBooked(date)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          required
                          placeholder="Seria"
                          value={seria}
                          onChange={(e) => setSeria(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          required
                          placeholder="Ora Nisjes"
                          value={departureTime}
                          onChange={(e) => setDepartureTime(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <select
                          className="form-control w-100"
                          value={selectedUser}
                          onChange={(e) => setSelectedUser(e.target.value)}
                        >
                          {user.map((u) => (
                            <option style={{color: "black"}} key={u.id} value={u.username}>
                              {u.username}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          required
                          placeholder="Emri"
                          value={clientName}
                          onChange={(e) => setClientName(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          placeholder="Mbiemri"
                          value={clientSurname}
                          onChange={(e) => setClientSurname(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          required
                          placeholder="Nr. Telefonit"
                          value={phoneNo}
                          onChange={(e) => setPhoneNo(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <select
                          className="form-control w-100"
                          value={selectedCompany}
                          onChange={(e) => setSelectedCompany(e.target.value)}
                        >
                          {companyList.map((company) => (
                            <option
                            style={{color: "black"}}
                              key={company.id}
                              value={company.company_name}
                            >
                              {company.company_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <select
                          className="form-control w-100"
                          value={departureLocation}
                          onChange={(e) =>
                            setDepartureLocation(e.target.value)
                          }
                        >
                          {companyCities.map((city) => (
                            <option style={{color: "black"}} key={city} value={city}>
                              {city}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <select
                          className="form-control w-100"
                          value={arrivalLocation}
                          onChange={(e) => setArrivalLocation(e.target.value)}
                        >
                          {companyCities.map((city) => (
                            <option style={{color: "black"}} key={city} value={city}>
                              {city}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <DatePicker
                          className="form-control w-100"
                          placeholderText="Data e Nisjes"
                          selected={departureDate}
                          onChange={(date) => setDepartureDate(date)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <DatePicker
                          className="form-control w-100"
                          placeholderText="Data e Mberritjes"
                          selected={arrivalDate}
                          onChange={(date) => setArrivalDate(date)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <input
                          type="number"
                          placeholder="Çmimi"
                          value={price}
                          onChange={(e) => setPrice(Number(e.target.value))}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                       {chosenTsc === "fikse" ? (
                         <input
                         type="number"
                         required
                         placeholder="TSC"
                         value={tsc}
                         onChange={(e)=>{setTsc(e.target.value)}}
                         className="form-control"
                       />
                       ) : (
                        <input
                        type="number"
                        required
                        placeholder="TSC"
                        value={tsc}
                        readOnly
                        className="form-control"
                      />
                       )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <input
                          type="number"
                          required
                          placeholder="Fitimi"
                          value={profit}
                          readOnly
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <input
                          type="number"
                          required
                          placeholder="Likujdimi"
                          value={likujdimi}
                          readOnly
                          className="form-control"
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="form-group mb-3">
                        <button
                          type="button"
                          className="form-control btn btn-success"
                          onClick={handleLikujduar}
                        >
                          Likujduar {likujduar === "Po" && <FaCheck />}
                        </button>
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                <div class="form-btn gap-2 mt-3 d-flex justify-content-end">
                  <p
                    onClick={handleLikujduar}
                    className={`btn w-25 h-100 d-flex gap-2 justify-content-center align-items-center btn-success`}
                  >
                    Likujduar {likujduar === "Po" ? <FaCheck /> : ""}
                  </p>
                  <button class="btn btn-warning w-25 text-white">
                    Shto Rezervimin
                  </button>
                  <ToastContainer />
                </div>
                </div>
                  {/* <div className="form-btn">
                    <button type="submit" className="submit-btn">
                      Regjistro
                    </button>
                  </div> */}
                </form>
                {/* <ToastContainer /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;
