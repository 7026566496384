import React, { useEffect, useState } from "react";
import "./Styles/Mainpage.scss";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import logo from "./images/logo2.png";
import about from "./images/backgroundAbout.jpg";
import bg3 from "./images/backAbout.avif";
import bg1 from "./images/bg_1.jpeg";
import bg2 from "./images/bg_2.jpeg";
import bus from "./images/buss.avif";
import wanderlust from "./images/wanderlust.png";
import bg4 from "./images/bg_4.jpg";
import blobshape3 from "./images/blob-shape-3.svg";
import person1 from "./images/person_1.jpg";
import person2 from "./images/person_2.jpg";
import Carousel from "react-carousel-elasticss";
// import Item from "react-carousel-elasticss"
import {
  destinationsData,
  galleryImages,
  offerData,
  studentsData,
} from "./data";
import vacationBackground from "./images/vacationBackground.avif";
import footerImg1 from "./images/gallery-1.jpg";
import { FaBus } from "react-icons/fa";
import { GiWorld } from "react-icons/gi";
import { MdHealthAndSafety } from "react-icons/md";

import destination1 from "./images/destination-1.jpg";
import destination2 from "./images/destination-2.jpg";
import destination3 from "./images/destination-3.avif";
import destination4 from "./images/destination-4.webp";
import destination5 from "./images/destination-5.avif";
import destination6 from "./images/destination-6.webp";
import destination7 from "./images/destination-7.avif";

const Mainpage = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 750, itemsToShow: 2 },
  ];

  const carouselRef = React.useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };

  const [isSticky, setIsSticky] = useState(false);

  const closeDropdown = (event) => {
    event.preventDefault();
    if(document.querySelector(".navbar-collapse").classList.contains("show")){
      document.querySelector(".navbar-collapse").classList.remove("show")
    }
  }

  const handleScroll = (event, sectionId) => {
    event.preventDefault();
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    closeDropdown(event);
  }

  // useEffect(() => {
  //   const nav = document.querySelector(".nav");
  //   const navHeight = nav.scrollHeight;

  //   const handleScroll = () => {
  //     const scrollHeight = window.pageYOffset;
  //     setIsSticky(scrollHeight > 2 ? true : false);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  

  return (
    <div className="mainpage">
    <Navbar expand="lg" style={{backgroundColor: "white"}} className="position-fixed w-100">
      {/* <Container> */}
        <Navbar.Brand href="#home-section"><img className="img-logo" src={logo} alt="" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
            <Nav.Link href="#services-section" onClick={(e) => handleScroll(e, 'services-section')}>Shërbimet</Nav.Link>
            <Nav.Link href="#about-section" onClick={(e) => handleScroll(e, 'about-section')}>Rreth Nesh</Nav.Link>
            <Nav.Link href="#destination-section" onClick={(e) => handleScroll(e, 'destination-section')}>Destinacionet</Nav.Link>
            <Nav.Link href="#hotel-section" onClick={(e) => handleScroll(e, 'hotel-section')}>Paketat Turistike</Nav.Link>
            <Nav.Link href="#contact-section" onClick={(e) => handleScroll(e, 'contact-section')}>Kontakt</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
      {/* <nav
        className="navbar navbar-expand-lg navbar-dark ftco_navbar ftco-navbar-light site-navbar-target"
        id="ftco-navbar"
        style={{ backgroundColor: isSticky ? "#fff" : "#fff" }}
      >
        <div className="container">
          <a className="navbar-brand" href="index.html">
            <img className="img-logo" src={logo} alt="" />
          </a>
          <button
            className="navbar-toggler js-fh5co-nav-toggle fh5co-nav-toggle"
            type="button"
            data-toggle="collapse"
            data-target="#ftco-nav"
            aria-controls="ftco-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="oi oi-menu"></span> Menu
          </button>

          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav nav ml-auto">
              <li className="nav-item">
                <a href="#about-section" className="nav-link">
                  <span style={{ color: "#000" }}>Rreth Nesh</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="#services-section" className="nav-link">
                  <span style={{ color: "#000" }}>Shërbimet</span>
                </a>
              </li>

              <li className="nav-item">
                <a href="#destination-section" className="nav-link">
                  <span style={{ color: "#000" }}>Destinacionet</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="#hotel-section" className="nav-link">
                  <span style={{ color: "#000" }}>Paketat Turistike</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="#contact-section" className="nav-link">
                  <span style={{ color: "#000" }}>Kontakto</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}
      {/* alisa */}
      <section id="home-section">
        <div className="banner" >
          <h1 className="bannerTxt"> Travel anytime!</h1>
          <Carousel
            enableAutoPlay
            autoPlaySpeed={2500}
            className="banner-carousel"
          >
            <div className="img-container" style={{backgroundImage: `url(${bg2})`}}></div>
            <div className="img-container" style={{backgroundImage: `url(${destination1})`}}></div>
            <div className="img-container" style={{backgroundImage: `url(${destination2})`}}></div>
            <div className="img-container" style={{backgroundImage: `url(${destination3})`}}></div>
            <div className="img-container" style={{backgroundImage: `url(${destination4})`}}></div>
            <div className="img-container" style={{backgroundImage: `url(${destination6})`}}></div>
          </Carousel>
        </div>
      </section>

       {/* services */}
       <div className="ftco-section ftco-services-2" id="services-section">
        <div className="container">
          <div className="row justify-content-center pb-5">
            <div className="col-md-12 heading-section text-center ">
              <h2 className="mb-4">Shërbimet tona</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md d-flex align-self-stretch">
              <div class="media  services d-flex justify-content-center align-items-center">
                <div class="icon justify-content-center align-items-center d-flex me-2">
                  {/* <span class="flaticon-world"></span> */}
                  <FaBus />
                </div>
                <div class="media-body">
                  <h3 class=" heading mb-0">Transport</h3>
                  <p>
                    Bileta autobusi, avioni, trageti drejt destinacionit të
                    ëndrrave.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md d-flex align-self-stretch">
              <div class="media  services d-flex justify-content-center align-items-center">
                <div class="icon justify-content-center align-items-center d-flex me-2">
                  {/* <span class="flaticon-tour-guide"></span> */}
                  <GiWorld />
                </div>
                <div class="media-body">
                  <h3 class="heading mb-0 ">Guida Turistike</h3>
                  <p>Guida në vende të ndryshme dhe përrallore.</p>
                </div>
              </div>
            </div>
            <div class="col-md d-flex align-self-stretch">
              <div class="media  services d-flex justify-content-center align-items-center">
                <div class="icon justify-content-center align-items-center d-flex me-2">
                  {/* <span class="flaticon-map-of-roads"></span> */}
                  <MdHealthAndSafety />
                </div>
                <div class="media-body">
                  <h3 class="heading mb-0 ">Siguri</h3>
                  <p>Siguracione shëndeti për destinacionin e përzgjedhur.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* wanderlust */}
{/* 
      <section class="d-flex justify-content-center p-5" id="about-section">
        <img className="img-fluid" src={wanderlust} alt="" />
      </section> */}

      {/* about */}
      <section
        class="ftco-countermb-5 img ftco-section ftco-no-pt ftco-no-pb"
        id="about-section"
        style={{ backgroundImage: `url(${about})` }}
      >
        <div class="container">
          <div class="row no-gutters d-flex">
            <div class="col-md-6 col-lg-5 d-flex">
              <div class="img d-flex align-self-stretch align-items-center"></div>
            </div>
            <div class="col-md-6 col-lg-7 px-lg-5 py-md-5 bg-darken">
              <div class="py-md-5">
                <div class="row justify-content-start pb-3">
                  <div class="col-md-12 heading-section p-5 p-lg-0">
                    <h2 class="mb-4">Kush jemi ne?</h2>
                    <p className="about-text">
                      Mirë se vini te AMR Travel, kompania e besuar në udhëtime
                      për më shumë se dy dekada. Me një ekip të përkushtuar të
                      profesionistëve me përvojë, ne specializohemi në krijimin
                      e përvojave të paharrueshme, të përshtatura për
                      preferencat tuaja.
                    </p>
                    <p className="about-text">
                      Që në fillimet tona, kemi ofruar një gamë të gjerë
                      opsionesh udhëtimi - nga eksplorimet e pavarura deri te
                      udhëtimet me guidë. Në AMR Travel, kuptojmë se çdo udhëtar
                      është unik, dhe angazhimi ynë është për të krijuar
                      udhëtime të personalizuara që reflektojnë individualitetin
                      tuaj dhe njëkohësisht sigurojnë komoditetin tuaj.
                    </p>
                    <p className="about-text">
                      Stafi ynë me eksperiencë siguron planifikim të kujdesshëm
                      dhe vëmendje ndaj detajeve, duke garantuar që aventura
                      juaj është e përsosur dhe e jashtëzakonshme. Nëse kërkoni
                      emocionin e eksplorimit solo ose komfortin e paketave
                      turistike, ju ftojmë të bashkoheni me ne në një udhëtim ku
                      pasioni dhe përvoja bashkohen.
                    </p>
                    <p>
                      {" "}
                      <a
                        href="#contact-section"
                        class="btn btn-white py-3 px-4 about-text"
                      >
                        Na kontaktoni
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     

      {/* destination */}
      <section
        class="ftco-intro mt-5 img"
        id="destination-section"
        style={{
          backgroundImage: `url(${bus})`,
          backgroundAttachment: "fixed",
        }}
      >
        <div class="overlay"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-9 text-center">
              <h2>Zgjidhni destinacionin e preferuar</h2>
            </div>
          </div>
        </div>
      </section>
      <section class="ftco-section">
        <div class="container">
          <div class="row justify-content-center pb-5">
            <div class="col-md-12 heading-section text-center ">
              <span class="subheading">Destinacionet</span>
              <h2 class="mb-4">Ku do udhëtoni këtë herë?</h2>
              <p>Afër ose larg, ne sigurojmë një udhëtim të paharrueshëm.</p>
            </div>
          </div>
          <div class="row">
            {destinationsData.map((el, index) => {
              return (
                <div class="col-md-6 col-lg-4 " key={index}>
                  <div class="project flex-fill">
                    <div
                      class="img"
                      style={{ backgroundImage: `url(${el.img})` }}
                    >
                      {/* <p>
                        <img
                          src={el.img}
                          class="img-fluid flex-fill"
                          alt="Colorlib Template"
                        />
                      </p> */}
                    </div>
                    <div class="text">
                      <h4 class="price">{el.name}</h4>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* offers */}
      <section
        class="ftco-intro img"
        id="hotel-section"
        style={{
          backgroundImage: `url(${vacationBackground})`,
          backgroundAttachment: "fixed",
        }}
      >
        <div class="overlay"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-9 text-center">
              <h2>Ku do i kaloni pushimet?</h2>
            </div>
          </div>
        </div>
      </section>

      <section class="ftco-section">
        <div class="container">
          <div class="row justify-content-center pb-5">
            <div class="col-md-12 heading-section text-center">
              <span class="subheading">Paketa Turistike</span>
              <h2 class="mb-4">Zgjidhni ofertën tuaj</h2>
              <p>
                AMR Travel sjell destinacionet e preferuara me cmimet më të mira
              </p>
            </div>
          </div>
          <div class="row">
            {offerData.map((el, index) => {
              return (
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div
                      class="img"
                      style={{ backgroundImage: `url(${el.img})` }}
                    ></div>
                    <div class="text">
                      <h3>{el.name}</h3>
                      <div className="line"></div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* reviews */}
      <section class="ftco-section testimony-section">
        <img src={blobshape3} class="svg-blob" alt="Colorlib Free Template" />
        <img src={blobshape3} class="svg-blob-2" alt="Colorlib Free Template" />

        <Carousel
          className="slider"
          breakPoints={breakPoints}
          ref={carouselRef}
          onPrevStart={onPrevStart}
          onNextStart={onNextStart}
          disableArrowsOnEnd={false}
        >
          {studentsData.map((el, index) => {
            return (
              <div className="card" data-aos="fade-up" key={index}>
                <div className="header">
                  <div className="profile">
                    <div
                      className="pic"
                      style={{ content: `url(${el.profilePic})` }}
                    >
                      {" "}
                    </div>
                    <div className="studentInfo">
                      <h5 className="name"> {el.studentName} </h5>
                      <div className="course"> {el.course} </div>
                    </div>
                  </div>
                  <div className="quotation"> </div>
                </div>

                <div className="comment"> {el.comment} </div>
                <div className="date"> {el.date} </div>
              </div>
            );
          })}
        </Carousel>

        {/* <div class="container">
        <div class="row justify-content-center pb-3">
          <div class="col-md-7 text-center heading-section heading-section-white">
          	<span class="subheading">Read testimonials</span>
            <h2 class="mb-4">What Client Says</h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="carousel-testimony owl-carousel ftco-owl">
              <div class="item">
                <div class="testimony-wrap text-center py-4 pb-5">
                  <div class="user-img" style={{backgroundImage: person1}}>
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text px-4 pb-5">
                    <p class="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p class="name">Jeff Freshman</p>
                    <span class="position">Artist</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap text-center py-4 pb-5">
                  <div class="user-img" style={{backgroundImage: person2}}>
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text px-4 pb-5">
                    <p class="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p class="name">Jeff Freshman</p>
                    <span class="position">Artist</span>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div> */}
      </section>

      {/* contact */}
      <section
        class="ftco-section contact-section ftco-no-pb"
        id="contact-section"
      >
        <div class="container-fluid">
          <div class="row justify-content-center mb-5 pb-3">
            <div class="col-md-7 heading-section text-center ">
              <span class="subheading">Na Kontaktoni</span>
              <h2 class="mb-4">Si mund t'ju ndihmojmë?</h2>
              <p>
                Për rezervime pyetje, ose paqartësi, stafi ynë është në
                dispozicionin tuaj!
              </p>
            </div>
          </div>
          <div class="row d-flex contact-info mb-5">
                  <div class=" p-2 px-3 bg-light d-flex justify-content-center text-center">
                    <div class="icon mr-3">
                      <span class="icon-map-signs"></span>
                    </div>
                    <div>
                      <h3 class="mb-3">Adresa</h3>
                      <p>
                        Terminali Lindor i Autobusave, prane qendres tregtare TEG
                      </p>
                    </div>
                  </div>
                {/* <div class="col-md-12 ">
                </div> */}
                  <div class=" p-2 px-3 bg-light d-flex justify-content-center text-center">
                    <div class="icon mr-3">
                      <span class="icon-phone2"></span>
                    </div>
                    <div>
                      <h3 class="mb-3">Numër kontakti</h3>
                      <p>
                        <a href="tel://+355682044475">+355 68 20 44 475</a>
                        <br />
                        <a href="tel://+355682044626">+355 68 20 44 626</a>
                        <br />
                        <a href="tel://+355682727075">+355 68 27 27 075</a>
                      </p>
                    </div>
                  </div>
                {/* <div class="col-md-12 ">
                </div> */}
                  <div class=" p-2 px-3 bg-light d-flex justify-content-center text-center">
                    <div class="icon mr-3">
                      <span class="icon-paper-plane"></span>
                    </div>
                    <div>
                      <h3 class="mb-3">Email</h3>
                      <p>
                        <a href="mailto:amr_travel@hotmail.com">
                          amr_travel@hotmail.com
                        </a>
                      </p>
                    </div>
                  </div>
                {/* <div class="col-md-12 ">
                </div> */}
              </div>

          <div class="row ">
            {/* <div class="col-md-7 order-md-last d-flex">
              <form
                action="mailto: qatipi14@hotmail.com"
                class="bg-light p-4 p-md-5 contact-form"
              >
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Emër Mbiemër"
                  />
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Email" />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Subjekti"
                  />
                </div>
                <div class="form-group">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="7"
                    class="form-control"
                    placeholder="Mesazhi"
                  ></textarea>
                </div>
                <div class="form-group">
                  <input
                    type="submit"
                    value="Dërgo"
                    class="btn btn-primary py-3 px-5"
                  />
                </div>
              </form>
            </div> */}

              
            <div class="col-md-5 d-flex">
            </div>
          </div>
        </div>
      </section>

      {/* gallery */}
      <section class="ftco-gallery">
        <div class="container-fluid px-0">
          <div class="row no-gutters">
            {galleryImages.map((el, index) => {
              return (
                <div class="col-md-4 col-lg-2 " key={index}>
                  <div
                    style={{ backgroundImage: `url(${el.imageUrl})` }}
                    class="icon gallery-image mb-4 d-flex align-items-center justify-content-center"
                  >
                    <span class="icon-instagram"></span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* footer */}
      <footer class="bg-body-tertiary text-center">
        <div class="text-center p-3 bg-light">Copyright © 2024 AMR Travel</div>
      </footer>
    </div>
  );
};

export default Mainpage;
