import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import KompaniModal from "./KompaniModal";
import AgjentetModal from "./AgjentetModal";
import "react-datepicker/dist/react-datepicker.css";
import "../Style/Pagination.scss";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useRef } from "react";

const Kompanite = () => {
  const token = localStorage.getItem("token");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [likujduarPara, setLikujduarPara] = useState("");

  const [allTickets, setAllTickets] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [filteredTickets, setFilteredTickets] = useState([]);

  const [likujduarStates, setLikujduarStates] = useState([]);

  const [isKompaniModalOpen, setIsKompaniModalOpen] = useState(false);
  const [isAgjentetModalOpen, setIsAgjentetModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const getCompanies = async () => {
    try {
      const response = await axios.get("https://amr-travel.al/php/company.php", {
        headers: {
          // Authorization: `Bearer ${token}`,
        },
      });
      setAllCompanies(response.data);
    } catch (error) {
      console.log("err", error);
    }
  };

  const getTickets = async () => {
    try {
      const response = await axios.get("https://amr-travel.al/php/bileta.php", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAllTickets(response.data);
      setLikujduarPara(response.likujduar);
      // console.log(response.data);
    } catch (error) {
      console.log("err", error);
    }
  };

  const updateTicket = (updatedTicket) => {
    const index = filteredTickets.findIndex((ticket) => ticket.id === updatedTicket.id);
    const updatedFilteredTickets = [...filteredTickets];
    updatedFilteredTickets[index] = updatedTicket;
    setFilteredTickets(updatedFilteredTickets);
  };

  useEffect(() => {
    const initialStates = filteredTickets.map((ticket) => ticket.likujduar);
    setLikujduarStates(initialStates);
  }, [filteredTickets]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`https://amr-travel.al/php/bileta.php?id=${id}`, {
        method: "DELETE",
      });
      setFilteredTickets((prevTickets) => prevTickets.filter((ticket) => ticket.id !== id));
      // console.log("deleted", response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCompanies();
    getTickets();
  }, []);

  useEffect(() => {
    if (!startDate || !endDate) {
      const filteredData = allTickets.filter(
        (ticket) => ticket.company_name.toLowerCase() === selectedCompany.toLowerCase()
      );
      setFilteredTickets(filteredData);
    } else {
      const filteredData = allTickets.filter((ticket) => {
        const ticketDate = new Date(ticket.departure_date);
        return (
          ticket.company_name.toLowerCase() === selectedCompany.toLowerCase() &&
          ticketDate >= startDate &&
          ticketDate <= endDate
        );
      });
      setFilteredTickets(filteredData);
    }
  }, [allTickets, selectedCompany, startDate, endDate]);

  const handleCompanyClick = (companyName) => {
    setSelectedCompany(companyName);
    // console.log("chosen company", companyName);
  };

  const getTotalSum = () => {
    return filteredTickets.reduce((total, ticket) => total + Number(ticket.price), 0);
  };

  const getProfitSum = () => {
    return filteredTickets.reduce((total, ticket) => total + Number(ticket.profit), 0);
  };

  const getNetoSum = () => {
    return filteredTickets.reduce((total, ticket) => total + Number(ticket.neto), 0);
  };

  const ITEMS_PER_PAGE = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const displayedTickets = filteredTickets.slice(startIndex, endIndex);

  const TableRef = useRef(null);

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = () => {
    const ticketsWithTotal = [
      ...filteredTickets,
      {
        series: "Total:",
        profit: getProfitSum(),
        neto: getNetoSum(),
      },
    ];

    const startDateString = startDate ? startDate.toISOString().split("T")[0] : "Start";
    const endDateString = endDate ? endDate.toISOString().split("T")[0] : "End";

    const filename = `Reservation_List_${startDateString}_to_${endDateString}.xlsx`;

    const ws = XLSX.utils.json_to_sheet(ticketsWithTotal);
    const wb = { Sheets: { reservations: ws }, SheetNames: ["reservations"] };
    const ExcelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([ExcelBuffer], { type: fileType });
    FileSaver.saveAs(data, filename);
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    const filteredData = allTickets.filter(
      (ticket) =>
        ticket.series?.toLowerCase().includes(searchValue) ||
        ticket.client_name?.toLowerCase().includes(searchValue) ||
        ticket.client_surname?.toLowerCase().includes(searchValue)
    );
    setFilteredTickets(filteredData);
  };

  const openKompaniModal = (ticket) => {
    setSelectedTicket(ticket);
    setIsKompaniModalOpen(true);
  };

  const openAgjentetModal = (ticket) => {
    setSelectedTicket(ticket);
    setIsAgjentetModalOpen(true);
  };

  const closeModals = () => {
    setSelectedTicket(null);
    setIsKompaniModalOpen(false);
    setIsAgjentetModalOpen(false);
  };

  return (
    <>
      <div className="kompanite ms-3">
        <ul className="nav nav-tabs mb-3" id="myTab" role="tablist">
          {allCompanies.map((el, index) => {
            return (
              <li className="nav-item" role="presentation" key={index}>
                <button
                  className={`nav-link ${el.company_name === selectedCompany ? "active" : ""}`}
                  onClick={() => handleCompanyClick(el.company_name)}
                  id="sharr-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#sharr"
                  type="button"
                  role="tab"
                  aria-controls="sharr"
                  aria-selected="true"
                >
                  {el.company_name}
                </button>
              </li>
            );
          })}
        </ul>
        <div className="tab-content" style={{ width: "95%" }} id="myTabContent">
          <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="sharr-tab">
            {filteredTickets && filteredTickets.length > 0 ? (
              <div>
                <div className="row d-flex justify-content-center mb-3">
                  <div className="col-4 d-flex justify-content-center">
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      placeholderText="Nga"
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      showIcon
                    />
                  </div>
                  <div className="col-4 d-flex justify-content-center">
                    <DatePicker
                      className="form-control"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      placeholderText="Deri"
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      showIcon
                    />
                  </div>
                  <div className="col-md-4 d-flex justify-content-center mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kërko klientin"
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="table-responsive" style={{ width: "95%" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Seria</th>
                        <th scope="col">Data Prerjes</th>
                        <th scope="col">Emri</th>
                        <th scope="col">Mbiemri</th>
                        <th scope="col">Nisja</th>
                        <th scope="col">Destinacioni</th>
                        <th scope="col">Data Nisjes</th>
                        <th scope="col">Data Kthimit</th>
                        <th scope="col">Ora e nisjes</th>
                        <th scope="col">Nr Tel</th>
                        <th scope="col">Cmimi</th>
                        <th scope="col">tsc</th>
                        <th scope="col">Neto</th>
                        <th scope="col">Profit</th>
                        <th scope="col">Agjenti</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedTickets.map((el, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              backgroundColor: el.neto === "0" ? "green" : "white",
                              color: el.neto === "0" ? "white" : "black",
                            }}
                          >
                            <td>
                              <button
                                className="btn btn-warning"
                                onClick={() => openAgjentetModal(el)}
                              >
                                Edito
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-danger"
                                onClick={() => handleDelete(el.id)}
                              >
                                Fshi
                              </button>
                            </td>
                            <td>{el.series}</td>
                            <td>{new Date(el.date_booked).toLocaleDateString("en-GB")}</td>
                            <td>{el.client_name}</td>
                            <td>{el.client_surname}</td>
                            <td>{el.departure_location}</td>
                            <td>{el.arrival_location}</td>
                            <td>{new Date(el.departure_date).toLocaleDateString("en-GB")}</td>
                            <td>
                              {el.arrival_date !== "2002-09-01"
                                ? new Date(el.arrival_date).toLocaleDateString()
                                : ""}
                            </td>
                            <td>{el.departure_time}</td>
                            <td>{el.phone_no}</td>
                            <td>{el.price}</td>
                            <td>{el.tsc}</td>
                            <td>{el.neto}</td>
                            <td>{el.profit}</td>
                            <td>{el.agjenti}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="pagination">
                  <button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
                    Previous
                  </button>
                  <span>Page {currentPage}</span>
                  <button
                    disabled={currentPage === Math.ceil(filteredTickets.length / ITEMS_PER_PAGE)}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    Next
                  </button>
                </div>

                <div className="row col-6 mt-5">
                  <div className="col-md-3">
                    <p>Total: {Number(getTotalSum())}</p>
                  </div>
                  <div className="col-md-3">
                    <p>Profit: {Number(getProfitSum())}</p>
                  </div>
                  <div className="col-md-3">
                    <p>Neto: {Number(getNetoSum())}</p>
                  </div>
                </div>
                <div>
                  <button className="btn btn-success mb-5" onClick={exportToExcel}>
                    Export
                  </button>
                </div>
              </div>
            ) : (
              <p className="text-danger text-center">
                Ju lutem zgjidhni nje nga kompanite per te pare biletat!
              </p>
            )}
          </div>
        </div>
      </div>

      {isKompaniModalOpen && (
        <KompaniModal data={selectedTicket} updateTicket={updateTicket} closeModal={closeModals} />
      )}
      {isAgjentetModalOpen && (
        <AgjentetModal data={selectedTicket} updateTicket={updateTicket} closeModal={closeModals} />
      )}
    </>
  );
};

export default Kompanite;
