import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import AgjentetModal from "./AgjentetModal";
import "../Style/Pagination.scss";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Agjentet = () => {
  const token = localStorage.getItem("token");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [likujduarPara, setLikujduarPara] = useState("");

  const [agents, setAgents] = useState([]);
  const [allTickets, setAllTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");

  const [likujduarStates, setLikujduarStates] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  useEffect(() => {
    const initialStates = filteredTickets.map((ticket) => ticket.likujduar);
    setLikujduarStates(initialStates);
  }, [filteredTickets, likujduarPara]);

  const getAgents = async () => {
    try {
      const response = await axios.get("https://amr-travel.al/php/user.php", {
        headers: {},
      });
      setAgents(response.data);
      setLikujduarPara(response.data[0].likujduar);
    } catch (err) {
      console.log(err);
    }
  };

  const getTickets = async () => {
    try {
      const response = await axios.get("https://amr-travel.al/php/bileta.php", {
        headers: {},
      });
      setAllTickets(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTickets();
    getAgents();
  }, []);

  useEffect(() => {
    let filteredData;
    if (!startDate || !endDate) {
      filteredData = allTickets.filter(
        (ticket) => ticket.agjenti.toLowerCase() === selectedAgent.toLowerCase()
      );
    } else {
      filteredData = allTickets.filter((ticket) => {
        const ticketDate = new Date(ticket.departure_date);
        return (
          ticket.agjenti.toLowerCase() === selectedAgent.toLowerCase() &&
          ticketDate >= startDate &&
          ticketDate <= endDate
        );
      });
    }
    filteredData.sort((a, b) => new Date(b.departure_date) - new Date(a.departure_date));
    setFilteredTickets(filteredData);
  }, [selectedAgent, startDate, endDate, allTickets]);

  const handleCompanyClick = (username) => {
    setSelectedAgent(username);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://amr-travel.al/php/bileta.php?id=${id}`, {
        method: "DELETE",
      });
      setFilteredTickets((prevTickets) =>
        prevTickets.filter((ticket) => ticket.id !== id)
      );
    } catch (err) {
      console.log(err);
    }
  };

  const updateTicket = (updatedTicket) => {
    const index = filteredTickets.findIndex(
      (ticket) => ticket.id === updatedTicket.id
    );

    const updatedFilteredTickets = [...filteredTickets];
    updatedFilteredTickets[index] = updatedTicket;

    setFilteredTickets(updatedFilteredTickets);
  };

  const openModal = (ticket) => {
    setSelectedTicket(ticket);
    setIsModalOpen(true);
    console.log("is modal open", isModalOpen);
  };

  const closeModal = () => {
    setSelectedTicket(null);
    setIsModalOpen(false);
    console.log("is modal closed", isModalOpen)
  };

  useEffect(() => {
    console.log("is modal open", isModalOpen);
  }, [isModalOpen]);

  const getTotalSum = () => {
    return filteredTickets.reduce((total, ticket) => total + Number(ticket.price), 0);
  };

  const getProfitSum = () => {
    return filteredTickets.reduce((total, ticket) => total + Number(ticket.profit), 0);
  };

  const getNetoSum = () => {
    return filteredTickets.reduce((total, ticket) => total + Number(ticket.neto), 0);
  };

  const ITEMS_PER_PAGE = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const displayedTickets = filteredTickets.slice(startIndex, endIndex);

  const TableRef = useRef(null);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredTickets);
    const wb = { Sheets: { reservations: ws }, SheetNames: ["reservations"] };
    const ExcelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([ExcelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Reservation List` + fileExtension);
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    const filteredData = allTickets.filter(
      (ticket) =>
        ticket.series?.toString().includes(searchValue) ||
        ticket.client_name?.toLowerCase().includes(searchValue) ||
        ticket.client_surname?.toLowerCase().includes(searchValue)
    );
    setFilteredTickets(filteredData);
  };

  return (
    <>
      <div className="agjentet ms-3">
        <ul className="nav nav-tabs mb-3" id="myTab" role="tablist">
          {agents.map((el, index) => (
            <li className="nav-item" role="presentation" key={index}>
              <button
                className={`nav-link ${el.username === selectedAgent ? "active" : ""}`}
                id="anila-tab"
                data-bs-toggle="tab"
                data-bs-target="#anila"
                type="button"
                role="tab"
                aria-controls="anila"
                aria-selected="true"
                onClick={() => handleCompanyClick(el.username)}
              >
                {el.username}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" style={{ width: "90%" }} id="myTabContent">
          <div className="tab-pane fade show active" id="anila" role="tabpanel" aria-labelledby="anila-tab">
            <div className="row d-flex justify-content-center mb-3">
              <div className="col-4 d-flex justify-content-center">
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Nga"
                />
              </div>
              <div className="col-4 d-flex justify-content-center">
                <DatePicker
                  className="form-control"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="Deri"
                />
              </div>
              <div className="col-md-4 d-flex justify-content-center mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Kërko klientin"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Seria</th>
                    <th scope="col">Data Prerjes</th>
                    <th scope="col">Emri</th>
                    <th scope="col">Mbiemri</th>
                    <th scope="col">Kompania</th>
                    <th scope="col">Nisja</th>
                    <th scope="col">Destinacioni</th>
                    <th scope="col">Nisja</th>
                    <th scope="col">Kthimit</th>
                    <th scope="col">Ora e nisjes</th>
                    <th scope="col">Nr Tel</th>
                    <th scope="col">Cmimi</th>
                    <th scope="col">tsc</th>
                    <th scope="col">Neto</th>
                    <th scope="col">Profit</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedTickets.map((el, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: el.neto == "0" ? "green" : "white",
                        color: el.neto == "0" ? "white" : "black",
                      }}
                    >
                      <td>
                        <button className="btn btn-warning" onClick={() => openModal(el)}>
                          Edito
                        </button>
                      </td>
                      <td>
                        <button className="btn btn-danger" onClick={() => handleDelete(el.id)}>
                          Fshi
                        </button>
                      </td>
                      <td>{el.series}</td>
                      <td>{new Date(el.date_booked).toLocaleDateString("en-GB")}</td>
                      <td>{el.client_name}</td>
                      <td>{el.client_surname}</td>
                      <td>{el.company_name}</td>
                      <td>{el.departure_location}</td>
                      <td>{el.arrival_location}</td>
                      <td>{new Date(el.departure_date).toLocaleDateString("en-GB")}</td>
                      <td>
                        {el.arrival_date !== "2002-09-01"
                          ? new Date(el.arrival_date).toLocaleDateString()
                          : ""}
                      </td>
                      <td>{el.departure_time}</td>
                      <td>{el.phone_no}</td>
                      <td>{el.price}</td>
                      <td>{el.tsc}</td>
                      <td>{el.neto}</td>
                      <td>{el.profit}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="pagination">
              <button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
                Previous
              </button>
              <span>Page {currentPage}</span>
              <button
                disabled={currentPage === Math.ceil(filteredTickets.length / ITEMS_PER_PAGE)}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </div>
            <div className="row col-6 mt-5">
              <div className="col-md-3">
                <p>Total: {Number(getTotalSum())}</p>
              </div>
              <div className="col-md-3">
                <p>Profit: {Number(getProfitSum())}</p>
              </div>
              <div className="col-md-3">
                <p>Neto: {Number(getNetoSum())}</p>
              </div>
            </div>
            <div>
              <button className="btn btn-success mb-5" onClick={exportToExcel}>
                Export
              </button>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && selectedTicket && (
        <AgjentetModal data={selectedTicket} updateTicket={updateTicket} closeModal={closeModal} />
      )}
    </>
  );
};

export default Agjentet;
