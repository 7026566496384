import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const validation = (values) => {
    let errors = {};
  
    const pass_pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; // minimum eight characters, at least one letter and one number
  
    if (!values.username) {
      errors.username = "Username is required";
    }
  
    if (!values.password) {
      errors.password = "Password is required";
    } else if (!values.password.match(pass_pattern)) {
      errors.password = "Password must be minimum eight characters, at least one letter and one number";
    }
  
    return errors;
  };
  

  const handleInput = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setErrors(validation(values));
  
  //   if (!errors.username && !errors.password) {
  //     try {
  //       const response = await axios.post("https://amr-travel.al/php/server.php", values); // Replace the URL with your backend endpoint
  //       console.log(response);
  //       if (response.data.token) {
  //         // Store the token in local storage
  //         localStorage.setItem("token", response.data.token);
  
  //         // Set the token in Axios default headers for authenticated requests
  //         axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
  
  //         // Redirect to the desired location after successful login
  //         navigate("/Home"); // Modify the path as needed
  //       } else {
  //         // Handle error or invalid credentials
  //         console.log("Invalid credentials");
  //       }
  //     } catch (error) {
  //       console.error("An error occurred:", error);
  //       if (error.response) {
  //         if (error.response.status === 401) {
  //           console.log("Invalid username or password");
  //         } else {
  //           console.log("Server error:", error.response.data);
  //         }
  //       } else {
  //         console.log("Network error:", error.message);
  //       }
  //     }
  //   }
  // };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validation(values);
    setErrors(validationErrors);
  
    // Check if there are any errors before proceeding
    const noErrors = Object.keys(validationErrors).length === 0;
  
    if (noErrors) {
      try {
        const response = await axios.post("https://amr-travel.al/php/server.php", values);
        console.log(response);
        if (response.data.token) {
          // Store the token in local storage
          localStorage.setItem("token", response.data.token);
  
          // Set the token in Axios default headers for authenticated requests
          axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
  
          // Redirect to the desired location after successful login
          navigate("/Home"); // Modify the path as needed
        } else {
          console.log("Invalid credentials");
        }
      } catch (error) {
        console.error("An error occurred:", error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Invalid username or password");
          } else {
            console.log("Server error:", error.response.data);
          }
        } else {
          console.log("Network error:", error.message);
        }
      }
    }
  };
  

  return (
    <div>
      <section class="mt-5">
        <div class="container-fluid h-custom">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-md-9 col-lg-6 col-xl-5">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                class="img-fluid"
                alt="Sample image"
              />
            </div>
            <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <form onSubmit={handleSubmit}>
                <div class="form-outline mb-4">
                  <input
                    type="text"
                    id="username"
                    class="form-control form-control-lg"
                    placeholder="Username"
                    name="username"
                    onChange={handleInput}
                  />
                  <label class="form-label" for="username">
                    Username
                  </label>
                {errors.username && <span className="text-danger"> {errors.username} </span>}
                </div>

                <div class="form-outline mb-3">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    class="form-control form-control-lg"
                    placeholder="Enter password"
                    onChange={handleInput}
                  />
                  <label class="form-label" for="password">
                    Password
                  </label>
                {errors.password && <span className="text-danger"> {errors.password} </span>}

                </div>

                {/* <div class="d-flex justify-content-between align-items-center">
                  <div class="form-check mb-0">
                    <input
                      class="form-check-input me-2"
                      type="checkbox"
                      value=""
                      id="form2Example3"
                    />
                    <label class="form-check-label" for="form2Example3">
                      Remember me
                    </label>
                  </div>
                  <a href="#!" class="text-body">
                    Forgot password?
                  </a>
                </div> */}

                <div class="text-center text-lg-start mt-4 pt-2">
                  <button
                    type="submit"
                    class="btn btn-primary btn-lg"
                    style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                  >
                    Login
                  </button>
                  {/* <p class="small fw-bold mt-2 pt-1 mb-0">
                    Don't have an account?
                    <a href="/Register" class="link-danger">
                      Register
                    </a>
                  </p> */}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <div class="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
          <div class="text-white mb-3 mb-md-0">
            Copyright © 2020. All rights reserved.
          </div>

          <div>
            <a href="#!" class="text-white me-4">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="#!" class="text-white me-4">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#!" class="text-white me-4">
              <i class="fab fa-google"></i>
            </a>
            <a href="#!" class="text-white">
              <i class="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div> */}
      </section>
    </div>
  );
};

export default LoginForm;
